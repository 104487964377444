// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery'
import 'jquery-ujs'


// require("@rails/ujs").start()
require("@rails/activestorage").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.jQuery = window.$ = $

import '../components/stimulus'
import '../components/xhrml'
import '../components/fontawesome'
import '../components/flatpickr'
import '../components/actiontext'
import '../_bootstrap-ext'
import '../frontend'


$(function() {
  $(document).trigger("content:loaded")
})
