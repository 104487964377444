import { Controller } from "stimulus"
import SignaturePad from 'signature_pad'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "canvas", "input", "container" ]

  initialize() {
    this.resize = this.resize.bind(this)
  }

  connect() {
    const container = $(this.containerTarget)

    this.canvas = $("<canvas>").get(0)

    $(this.containerTarget).append(this.canvas)
    $(this.inputTarget).on("disabled.whiteboard", (event) => {
      if (!event.target.disabled) {
        this.resize()
      }
    })

    this.signature = new SignaturePad(this.canvas, {
      throttle: 1,
      onEnd: () => this.save()
    })

    $(window).on("resize", this.resize)
    this.resize()
  }

  disconnect() {
    $(this.canvas).remove()
    $(window).off("resize", this.resize)
    $(this.inputTarget).off("disabled.whiteboard")
  }

  resize() {
    const container = $(this.containerTarget)
    const width = container.width()
    const height = container.height()

    if (height <= 0 || width <= 0) {
      return
    }

    this.canvas.height = height
    this.canvas.width = width
  }

  clear() {
    this.signature.clear()
    this.inputTarget.setAttribute('disabled', true)
  }

  save() {
    const data = this.signature.toDataURL('image/png')
    this.inputTarget.value = data
    this.inputTarget.removeAttribute('disabled')
  }
}
