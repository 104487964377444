import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "uploaderTemplate", "maxImageCount" ]

  connect() {
    this.appendUploader()
  }

  appendUploader() {
    const inputs = $(this.element).find("input")
    if ((inputs.length == 0 || inputs[inputs.length - 1].value != "") && inputs.length <= this.maxImageCountTarget.value ){
      this.element.appendChild(this.uploaderTemplateTarget.content.cloneNode(true))
    }
  }

  clearInput(event) {
    $(event.target).parents(".col-6").remove()
    this.appendUploader()
  }

  showRemoveButton(event) {
    $(event.target).parents(".col-6").find("a").removeClass("d-none")
  }
}
