import 'bootstrap'
import './style.scss'

$(document).on('content:loaded', function() {
  $('.is-invalid').each(function() {
    if ($(this).hasClass('horizontal_error')) {
      $(this).parent().class('display', 'flex');
      $(this).parent().class('align-items', 'baseline');
    }
  });
});
