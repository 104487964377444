import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "timestamp", "timer", "resend" ]

  connect() {
    const controller = this
    this.tick(controller)
    this.intervalId = setInterval(this.tick, 1000, controller)
  }

  disconnect() {
    clearInterval(this.intervalId)
  }

  tick(controller) {
    // default waiting time is 60 seconds
    const resetTime = parseInt(controller.timestampTarget.innerHTML) + 60
    const remaining = resetTime - parseInt(new Date().getTime() / 1000)

    if (remaining > 0) {
      controller.timerTarget.innerHTML = (remaining + " 秒后重新获取")
    } else {
      $(controller.resendTarget).removeClass("d-none")
      $(controller.timerTarget).addClass("d-none")
    }
  }
}
