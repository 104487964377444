import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "preview", "input" ]

  updatePreview() {
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      const reader = new FileReader()
      const previewTarget = this.previewTarget

      reader.onload = function(event) {
        $(previewTarget).attr('src', event.target.result)
      }

      reader.readAsDataURL(this.inputTarget.files[0])
    }
  }

  showPicker() {
    this.inputTarget.click()
  }
}
